/* Container for the image */
.image-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    /* Adjust as needed */
    max-width: 600px;
    border-radius: 10px;
}

/* The image itself with a subtle zoom effect */
.image-container img {
    display: block;
    width: 100%;
    height: auto;
    transition: transform 0.5s ease, filter 0.5s ease;
    filter: grayscale(0%);
}

/* On hover, remove grayscale and slightly zoom the image */
.image-container:hover img {
    transform: scale(1.05);
    filter: grayscale(50%);
}

/* Overlay for gradient and text */
.image-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7));
    opacity: 0;
    transition: opacity 0.5s ease;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

/* On hover, the overlay fades in */
.image-container:hover .overlay {
    opacity: 1;
}

/* Text inside the overlay */
.image-container .overlay h3 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.image-container .overlay p {
    font-size: 16px;
    margin: 10px 0 0;
    line-height: 1.4;
}