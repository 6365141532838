.image-zoom {
    transition: transform 0.4s ease;
    /* Duration of the effect */
    transform: scale(1);
    /* Normal size */
}

/* On hover, the image zooms in */
.image-zoom:hover {
    transform: scale(1.1);
    /* Zoom in a little */
}