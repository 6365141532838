/* Container for the flip effect */
.flip-container {
    /* perspective: 1000px; */
    /* Adds depth to the 3D effect */
}

.flip-card {
    /* Adjust as needed */
    /* Adjust as needed */
    position: relative;
    transition: transform 0.6s;
    /* Smooth transition */
    transform-style: preserve-3d;
    /* Preserve 3D space */
}
.flip-card1 {
    /* Adjust as needed */
    /* Adjust as needed */
    position: relative;
    transition: transform 0.6s;
    /* Smooth transition */
    transform-style: preserve-3d;
    /* Preserve 3D space */
}

/* On hover, flip the card */
.flip-container:hover .flip-card {
    transform: rotateY(180deg);
}
.flip-container:hover .flip-card1 {
    transform: rotateY(-180deg);
}

/* Front side of the card */
.flip-card-front,
.flip-card-back {
    position: absolute;
    backface-visibility: hidden;
    /* Hide the back when flipped */
}

